import React, { useState, useContext, useEffect } from 'react';
import Cookie from 'universal-cookie';
import 'react-responsive-modal/styles.css';
import ResponsiveModal from 'react-responsive-modal';
import { Helmet } from 'react-helmet';

import * as modalStyle from 'styles/components/utils/reactModal.module.scss';
import * as style from 'styles/components/homepage/matches.module.scss';
import NewsBanner from 'components/homepage/NewsBannerNoThumbs';
import MerchBlockList from 'components/merch/BlockList';
// import NewsBlockListHomepage from 'components/news/BlockListHomepage';
import NewsBlockListHomepage from 'components/news/BlockListHomepageCategorized';
import SponsorAdsBanner from 'components/homepage/SponsorAdsBanner';
import MatchesHomepage from 'components/homepage/Matches';
// import Modal from 'components/utils/Modal';
// import Splash from 'components/match/Splash';
import Splash from 'components/match/SplashMultiple';
import useSanityConfigGlobal from 'data/queries/useSanityConfigGlobal';
import useHammarbyClub from 'data/queries/useHammarbyClub';
import useAllMatches from 'data/queries/useAllMatches';
import { LangContext } from 'context/LangContext';
import FormattedMessage from 'components/utils/FormattedMessage';
import FiftyFiftyBanner from 'components/utils/FiftyFiftyBanner';
import Calendar from 'components/match/Calendar';

import defaultImage from '../images/default-image.jpg';

const Homepage = ({ pageContext }) => {
    const { lang } = useContext(LangContext);

    const club = useHammarbyClub();
    const allMatches = useAllMatches();
    const globalConfig = useSanityConfigGlobal();

    const [filteredTeam, setFilteredTeam] = useState(null);
    const [open, setOpen] = useState(false);
    const [selectedMatchesModal, setSelectedMatchesModal] = useState(null);

    const onCloseModal = () => setOpen(false);

    const closeIcon = (
        <svg viewBox="0 0 36 36" data-testid="close-icon" fill="#ffffff" width="32" height="32"><path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"/></svg>
    )

    useEffect(() => {
        const now = new Date();
        const cookie = new Cookie();

        const selectedMatches = allMatches.filter((match) => {
            const matchDate = new Date(match.startEventDate);

            if(!match.competition) return false;

            if(match.team._id === 'team-253894' || match.team._id === 'team-2' || match.teamExternal._id === 'team-253894' || match.teamExternal._id === 'team-2') {
                if (matchDate < now) return false;

                const diff = Math.abs(new Date(match.startEventDate) - new Date());
                const diffDays = Math.ceil(diff/ (1000 * 3600 * 24));
                if (diffDays <= 1) {
                    if(match.hideSplash !== true) {
                        if(!cookie.get('matchOverlayShown')) {
                            cookie.set('matchOverlayShown', 'true', { path: '/' });
                            return true;
                        }
                    }
                }
            }
            return false;
        });
        setSelectedMatchesModal(selectedMatches)
        setOpen(true);
    }, [allMatches, setSelectedMatchesModal])

    const filteredNews = (items) => {
        const nonHTVItems = items
            .filter(
                (item) =>
                    (item.team.filter((team) => team.id === filteredTeam).length > 0 ||
                    filteredTeam === null) &&
                    !item.category.title.se.includes('HTV'),
            )
            .sort((a, b) => new Date(b.publishDate) - new Date(a.publishDate))
            .slice(0, 3);

        const HTVItems = items
            .filter(
                (item) =>
                    (item.team.filter((team) => team.id === filteredTeam).length > 0 ||
                    filteredTeam === null) &&
                    item.category.title.se.includes('HTV'),
            )
            .sort((a, b) => new Date(b.publishDate) - new Date(a.publishDate))
            .slice(0, 3);

        return nonHTVItems.concat(HTVItems);
    };

    return (
        <>
            <Helmet>
                <meta property="og:title" content="Hammarby Fotboll" />
                <meta property="og:site_name" content="Hammarby Fotboll" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content={defaultImage} />
                <meta property="og:url" content="https://www.hammarbyfotboll.se" />
            </Helmet>
            <ResponsiveModal
                open={open && selectedMatchesModal.length > 0}
                center
                classNames={{
                    overlay: modalStyle.modal__overlay,
                    modal: modalStyle.modal__modal,
                }}
                onClose={onCloseModal}
                closeIcon={closeIcon}
            >
                <Splash matches={selectedMatchesModal} />
            </ResponsiveModal>

            <NewsBanner />

            <ul className="tabs wrapper" style={{ display: 'none' }}>
                <li>
                    <button
                        onClick={() => setFilteredTeam(null)}
                        className={filteredTeam === null ? 'active' : ''}
                        type="button"
                        aria-label={<FormattedMessage id="global.allTeams" />}
                    >
                        <FormattedMessage id="global.allTeams" />
                    </button>
                </li>
                {club.teams.map((item, index) => (
                    <li key={index}>
                        <button
                            onClick={() => setFilteredTeam(item.id)}
                            className={filteredTeam === item.id ? 'active' : ''}
                            type="button"
                            aria-label={item.title[lang.translationKey] || item.title.en}
                        >
                            {item.title[lang.translationKey] || item.title.en}
                        </button>
                    </li>
                ))}
            </ul>
            {globalConfig && !globalConfig.offseason && (
                <MatchesHomepage
                    allMatches={allMatches}
                    filteredTeam={filteredTeam}
                />
            )}
            <SponsorAdsBanner />
            {globalConfig?.lottery?.enabled && globalConfig?.lottery?.homepageEnabled && globalConfig?.lottery?.lotteryId && (
                <div className="wrapper">
                    <FiftyFiftyBanner lottery={globalConfig.lottery} />
                </div>
            )}
            {pageContext.news.length > 0 && (
                <NewsBlockListHomepage
                    title={<FormattedMessage id="news.news" />}
                    items={filteredNews(pageContext.news)}
                />
            )}

            <div className={`${style.matches__calendar} wrapper hidden-desktop small-margin`}>
                <Calendar filteredTeam={filteredTeam} matches={allMatches} />
            </div>

            <MerchBlockList />
        </>
    );
};

export default Homepage;
